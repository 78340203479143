import React from 'react'

const HomeHeader = (props) => {

  return (
    <h1 style={{
      color: '#fff',
      fontSize: '1.85em',
      textAlign: "center",
      paddingTop: '4.5rem',
      position: 'relative'
    }}>{props.children}</h1>
  )
}

export default HomeHeader