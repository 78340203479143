import React from 'react'
import './body-content.css'


const BodyContent = (props) => {

  return (
    <div className={`body-content ${props.classes}`}>
      {props.children}
    </div>
  )
}

export default BodyContent