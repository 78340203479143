import React from 'react'
import { useStaticQuery } from "gatsby"
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import FeaturedPostCard from './featured-post-card'

const FeaturedPostList = (props) => {

  const data = useStaticQuery(graphql`
        query PostListQuery {
          allMarkdownRemark(
            limit: 2,
            sort: { order: DESC, fields: [frontmatter___date] },
            filter: {
              frontmatter: {path: {regex: "/blog/"}}
            }
          ) {
            edges {
              node {
                excerpt(pruneLength: 70)
                frontmatter {
                  title
                  path
                  date(formatString: "MMMM DD, YYYY")
                  tags
                  image {
                    src
                    alt
                    title
                  }
                }
              }
            }
          }
        }
      `)

  let { edges: posts } = data.allMarkdownRemark
  posts = posts.slice(0, props.limit)

  return (
    <Grid container spacing={3} justify="center">
      {(posts && 
        posts.map(({ node: post }, index) =>  (
          
          <Grid item sm={12} md={6} key={index} style={{width: '100%'}} >
            
            <FeaturedPostCard {...post} />
          </Grid>
        ))
      )}
    </Grid>

  )
}

FeaturedPostList.propTypes = {
  limit: PropTypes.number,
  categories: PropTypes.arrayOf(PropTypes.string)
}

export default FeaturedPostList
