import React from "react"
import '../fonts/Roboto-Black.ttf';
import '../fonts/Roboto-Bold.ttf';
import '../fonts/Roboto-Regular.ttf';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../theme/blog-theme'


import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import { Link } from 'gatsby'
import FeaturedPostList from '../components/featured-posts/featured-post-list'
import HomeHeader from '../components/home-header/home-header'
import { Container } from "@material-ui/core";
import BodyContent from "../components/content/body-content";
import '../templates/blog-post.css'

const IndexPage = (props) => {

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Home" />
        <Hero height={'450px'} position={'absolute'}/>
        <Container maxWidth="lg">
          <HomeHeader>
            Featured Tech Post
          </HomeHeader>
        </Container>
        <Container maxWidth="md" style={{position: 'relative'}}>
          <FeaturedPostList limit={1} categories={['featured-post']} />
        </Container>
        <Container maxWidth="md">
            <BodyContent classes={"blog-post-content"}>
              <h2 style={{color: theme.palette.primary.main}}>Upgrade Your Knowledge</h2>
              <p>
                Use this blog to help you prepare for yourself for a new career, supplement your current skills or
                or just keep learning new skills. Life is a never ending journey, and I aim to keep myself fulfilled both spiritually and mentally.
                I aim to help others do the same with this blog and hope I accomplish my mission.
              </p>
              <h2 style={{color: theme.palette.primary.main}}>Take a Load off</h2>
              <p>
                Tech How To will cover all areas of tech, that will pique the interest of developers, and hopefully anyone else chained to a desk.
                I'll share anything I learn along the way and hope that it helps you become happier in your career, or life in general.
                Any work that I've done that I feel is noteworthy or interesting will also be posted in the <Link to="/work">Work Section. </Link> 
                If you have any comments or concerns, please direct them to the author, <a href="mailto:jhurl824@gmail.com">Justin Hurley.</a>
              </p>
            </BodyContent>
          </Container>  
      </Layout>
    </ThemeProvider>
  )
}

export default IndexPage
